export const ABOUT_US_PATH = '/about/'
export const AGENTS_PATH = '/agents/'
export const AGENTS_SCHEDULE_SUCCESS = '/agents/schedule-call-success/'
export const BLOG_PATH = '/blog/'
export const CAREERS_PATH = '/careers/'
export const FAQ_PATH = '/faq/'
export const HOMEPAGE_PATH = '/'
export const HOW_IT_WORKS_PATH = '/how-it-works/'
export const INVEST_WITH_FLOCK_PATH = '/invest-with-flock/'
export const INVESTOR_PATH = process.env.GATSBY_FLOCK_INVESTOR_URL || '/'
export const ONBOARDING_PATH = '/onboarding/'
export const PARTNER_PATH = process.env.GATSBY_FLOCK_PARTNER_URL || '/'
export const PORTFOLIO_PATH = '/portfolio/'
export const PRIVACY_PATH = '/privacy/'
export const PRIVACY_PATH_ARCHIVED = '/arch/privacy-100620/'
export const RENT_SELL_FLOCK_CALCULATOR_PATH = '/rental-property-calculator/'
export const STORIES_PATH = '/stories/'
export const TERMS_OF_USE_PATH = '/terms-of-use/'
export const WHAT_IS_FLOCK_PATH = '/what-is-flock/'

export const CUSTOMER_OFFER_PAGE_PATH = `/portfolio-estimate`
export const OFFER_PAGE_PATH = '/prelim-estimate'
